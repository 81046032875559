
:root {
    /** FONT SIZE **/
    --font-size-small: 15px;

    /** PRIMARY : 467F8E **/
    --color-primary: #467F8E;
    /** SECONDARY : 56B8D0 **/
    --color-secondary: #56B8D0;
    /** PRIMARY LIGHT : A3BFC7 **/
    --color-primary-light: #A3BFC7;
    /** OSCURO : 6f6f6f (D9D9D9 + 3 capas encima 20% opacidad) **/
    --color-dark: #6f6f6f;
    /** CLARO : C0D0EF **/
    --color-light: #C0D0EF;
    /** ACENTO : C5273E **/
    --color-highlight: #C5273E;
    /** ACENTO LIGHT : E2939F **/
    --color-highlight-light: #E2939F;
    /** GRIS : C4C4C4 **/
    --color-grey: #C4C4C4;

    --color-grey-dark: #9E9E9E;
    /** VERDE : 14b414 **/
    --color-green: #14b414;
    /** AMARILLO : ffbf00 **/
    --color-yellow: #ffbf00;
    /** ROJO : ff1616 **/
    --color-red: #ff1616;
    /** ROJO CLARO : ed6b6b **/
    --color-light-red: #ed6b6b;
    /** AZUL : ff1616 **/
    --color-blue: #004085;
    /** AZUL CLARO : ed6b6b **/
    --color-light-blue: #cce5ff;

    /** INPUT Background: EDF3F4 **/
    --color-input-bg: #EDF3F4;

    /* color para los box-shadow */
    --color-card-box-shadow: #467e8e77;

    /* color para el hover de los botones con color primary */;
    --color-button-hover-primary: #58A1B4;

    /* color para el hover de los botones con color secondary */;
    --color-button-hover-secondary: #218FA5;

    --color-ausencia-vacaciones: #bfedc1;
    --color-ausencia-baja: #f8d7da;
    --color-ausencia-teletrabajo: #d8bba6;
    --color-ausencia-otros: #cce5ff;
    --color-ausencia-no-retribuida: #e2e3e5;
    --color-ausencia-asuntos-propios: #c1e9e7;
    --color-festivo: rgb(220, 172, 244);

    --color-link: #2f80ed;

}


