@import 'bootstrap-variables';
@import 'bootstrap/scss/functions';
@import 'bootswatch/dist/litera/variables';
@import 'bootstrap/scss/variables';
@import "~@ng-select/ng-select/themes/default.theme.css";

/* ==============================================================
Bootstrap tweaks
===============================================================*/

* {
  font-family: 'Poppins'!important;
}

html {
  font-size: 12.5px;
}

.tooltip-inner {
  font-size: 0.9rem;
}


body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* other generic styles */
.estado-verde {
  border-radius: 50px ;
  background-color: rgba(201, 242, 155, 0.3);
  padding: 5px;
  color: var(--color-green);
}
.estado-rojo {
  border-radius: 50px ;
  background-color: rgba(255, 99, 71, 0.3);
  padding: 5px;
  color:var(--color-red);
}
.estado-amarillo {
  border-radius: 50px;
  background-color: rgb(255 193 7 / 23%);
  padding: 5px;
  color: var(--color-yellow);
}

.bg-azul {
  border-radius: 50px;
  background-color: var(--color-light-blue);
  padding: 8px 20px;
  color: var(--color-blue);
}

.jh-card {
  padding: 0.5%;
  background: white;
  border: none;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.form-control {
  background-color: #fff;
}

/* ==========================================================================
switch slider customized
========================================================================== */

.form-switch-slider {
  display: flex;
  align-items: center;

  label {
    flex: 0 0 auto;
    width: auto;
    &:has(input) {
      cursor: pointer;
    }

    &:has(input:disabled) {
      cursor: not-allowed !important;
    }

    &.form-switch {
      position: relative;
      width: 30px;
      height: 15px;
      background-color: #b8b3b3;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 2em;

      &:has(input:checked) {
        background-color: #b9ced4;
      }

      input {
        cursor: inherit;
        position: absolute;
        top: -2.5px;
        appearance: none;
        display: inline-block;
        border-radius: 50%;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
        background-color: #FAFAFA;
        height: 20px;
        width: 20px;
        -webkit-transition: .4s;
        transition: .4s;


        &:checked {
          -webkit-transform: translateX(-18px);
          -ms-transform: translateX(-18px);
          transform: translateX(-18px);
          background-color: var(--color-primary);
        }
        &:not(:checked) {
          -webkit-transform: translateX(-42px);
          -ms-transform: translateX(-42px);
          transform: translateX(-42px);
        }

        &:disabled {
          background-color: var(--color-grey);
        }
      }
    }
  }

}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.hand,
[jhisortby] {
  cursor: pointer;
}

.background-card{
  background-color: #f8f9ff;
  padding: 0.5%;
}
.titulo {
  padding: 1% 1%;
  font-weight: 700;
  color: var(--color-primary);
  width: max-content;
}

img{
  &.azul{
    filter: invert(77%) sepia(48%) saturate(4111%) hue-rotate(206deg) brightness(108%) contrast(86%);
  }
  &.verde-img{
    filter: invert(46%) sepia(28%) saturate(1059%) hue-rotate(93deg) brightness(96%) contrast(92%);
  }
  &.rojo{
    filter: invert(56%) sepia(74%) saturate(4453%) hue-rotate(329deg) brightness(98%) contrast(89%);
  }
  &.naranja{
    filter: invert(50%) sepia(92%) saturate(349%) hue-rotate(343deg) brightness(105%) contrast(90%);
  }
  &.negro{
    filter: invert(1);
  }
}

.tabla-datos {
  margin: 0% 3% 3% 3%;
  &.header {
    background-color: #fafafa;
    color: var(--color-dark);
  }

  td {
    padding: 10px 3px;
  }
  th {
    padding: 10px 3px;
  }
}

.calendario-input {
  color: #9e9e9e;
  font-size: larger;
  transform: translateX(-35px) !important;
}

.ng-select-container{
  min-height: 40px !important;
  height: auto !important;
  .ng-value-container .ng-placeholder {
    top: auto !important;
  }
}

jhi-calendario-anual  {
  .tooltip-inner {
    background-color: white;
    font-size: medium;
    border: black 1px solid;
    max-width: none;
  }

  .tooltip {
    --bs-tooltip-opacity: 1;
  }
}


.disable {
  background-color: #f9f9f9 !important;
}
/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .jhi-toast {
    z-index: 100;
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }

    pre{
      white-space: break-spaces;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: 50%;
  }
}

/* ==========================================================================
entity list page css
========================================================================== */

.table-entities thead th .d-flex > * {
  margin: auto 0;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row-md.jh-entity-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .row-md.jh-entity-details > {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0.5em 0;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding: 0.5em 0;
      margin-left: 0;
    }
  }
}
.container-fluid{
  padding: 0;
}
/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu {
  padding-left: 0px;
}

.ngb-tp-input {
  text-align: center;
  border: none;
  background-color: #ffff;
}

jhi-calendario-anual .ngb-tp-input-container{
  button{
    color: var(--color-primary) !important;
    background-color: #fff !important;
  }
  button:hover{
    color: #9e9e9e !important;
  }
  button:focus{
    box-shadow: none !important;
  }
}

.ngb-tp-spacer{
  font-weight: 700 !important;
}

.fs-6{
  font-size: 1.1rem!important;
}

.fs-7{
  font-size: 1rem!important;
}

.align-sub {
  vertical-align: sub!important;
}

button {
  border-radius: 9px!important;
  font-weight: 700 !important;
  font-family: inherit!important;

  & label {
    font-size: 1.05rem;
  }

  & fa-icon {
    font-size: 1.4rem!important;
  }

  & fa-icon + * {
    margin-left: 0.5rem!important;
  }
}

button * {
  vertical-align: middle;
}

.btn-primary{
  background-color: var(--color-primary) !important;
  border: none!important;
}
.btn-primary:hover{
  background-color: var(--color-button-hover-primary) !important;
}
.btn-primary:disabled{
  background-color: var(--color-primary) !important;
  opacity: 0.7;
}
.btn-primary-inverted {
  background-color: transparent;
  border: 0;
  color: var(--color-primary);
}

.text-primary {
  color: var(--color-primary) !important;
}

.btn-info{
  background-color: var(--color-secondary) !important;
  border: none!important;
}
.btn-info:hover{
  background-color: var(--color-button-hover-secondary) !important;
}

.btn-verde {
  color: var(--color-green);
  background-color:rgba(201, 242, 155, 0.3) ;
}
.btn-verde:hover{
  background-color: var(--color-green);
  color:white ;
}

.btn-rojo {
  color: var(--color-red);
  background-color:rgba(255, 99, 71, 0.3) ;
}
.btn-rojo:hover {
  background-color: var(--color-red);
  color:white ;
}

.text-rojo-claro {
  color: var(--color-light-red);
}

.btn-amarillo {
  color: var(--color-yellow);
  background-color:rgba(255, 193, 7, 0.23) ;
}
.btn-amarillo:hover {
  background-color: var(--color-yellow);
  color:white ;
}

.text-gray {
  color: var(--color-grey);
}

.text-link {
  color: var(--color-link) !important;
}

.modal-content {
  background-image: url('../images/circulo-azul.svg'), url('../images/circulo-gris.svg'), url('../images/circulo-azul.svg'), url('../images/circulo-gris.svg');
  background-repeat: no-repeat;
  background-position: calc(100% + 61px) -9px, calc(100% + 6px) -62px, -15px calc(100% + 52px), -54px calc(100% - 8px);
  background-size: 126px;
}


.modal-footer {
  border-top: 0;
}

.modal-header .btn-close {
  user-select: none;
  box-shadow: none;
}

ng-select.tarea {
  .ng-select-container {
      border: 0;
  }
}

/* ==========================================================================
media queries
========================================================================== */

@media (max-width: 576px) {
  #sidebar:not(.expanded) {
    width: 60px;

    button:not(.menu-line){
      width: auto;
      padding: 0px 7px;
    }

    .iconos-sidebar{
      padding-right: 0px;
    }
  }

  #main-content{
    margin-left: 60px;
  }
}

@media (max-width: 420px) {
  #sidebar:not(.expanded) {
    left: -60px;
  }

  #main-content{
    margin-left: 0px;
  }

  jhi-toggle-sidebar .toggle {
    left: 18px;
  }
}

@media (max-width: 768px) {
  .sidebar .tooltip {
    display: none;
  }
}

/* ==========================================================================
angular-cli removes postcss-rtl processed inline css, processed rules must be added here instead
========================================================================== */
/* page-ribbon.component.scss */
.ribbon {
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* navbar.component.scss */
.navbar {
  ul.navbar-nav {
    .nav-item {
      margin-left: 0.5em;
    }
  }
}
/* jhipster-needle-scss-add-main JHipster will add new css style */

.avatar-iniciales {
    color: white;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    font-weight: 600;
    font-size: 40px;
    background: var(--color-primary);
    user-select: none;
}


.btn-remove {
  background-color: #d9534f !important;
}
.btn-remove:hover{
    background-color:#b84743 !important;
}

.cancelar-button{
  background-color: #f2f2f4 !important;
}
.cancelar-button:hover{
  background-color: var(--color-grey) !important;
}

.horarios .ngb-tp-input-container>*{
  padding: 0;
}
.horarios input{
  font-size: 13px;
}


.month-picker {
  .ngb-dp-week, .ngb-dp-header:not(.header-buttons) {
    display: none;
  }
}


.nav.nav-tabs{
  .borde-tab {
    border: 0px;
    border-bottom-width: 2px;
    border-bottom-color: var(--color-primary);
    border-bottom-style: solid;
  }

  .active {
    color: var(--color-primary)!important;
  }

  .nav-link {
    color: var(--color-dark);
    font-weight: 400;
  }

  &.title-tabs{
    .nav-link{
      font-size: 1.4rem;
      font-weight: 700;
    }

    .borde-tab {
      border-bottom-width: 5px;
    }
  }
}
.circular-landscape{
  vertical-align: middle;
    display: inline-block;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}
.circular-landscape img{
  width: auto;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
}

fa-icon[icon='spinner'] {
  color: var(--color-primary);
}


.tooltip-calendario-items .tooltip-inner {
  border: 0 !important;
  box-shadow: 0 3px 8px #999;
  padding: 10px;
  border-radius: 10px;
}

.tooltip-calendario-items .tooltip-arrow {
  --bs-tooltip-bg: white;
}

.bs-tooltip-top{
  bottom: 3px!important;
}

.tabla-datos-wrapper {
  overflow: auto;
  margin-top: 18px;
}


.filtros-calendario img {
  &.baja {
      filter: invert(14%) sepia(25%) saturate(4380%) hue-rotate(327deg) brightness(105%) contrast(99%);
  }
  &.vacaciones {
      filter: invert(19%) sepia(58%) saturate(1265%) hue-rotate(88deg) brightness(94%) contrast(107%); 
  }
  &.teletrabajo {
      filter: invert(38%) sepia(8%) saturate(3338%) hue-rotate(343deg) brightness(92%) contrast(83%);
  }
  &.otros {
      filter: invert(17%) sepia(82%) saturate(2014%) hue-rotate(197deg) brightness(93%) contrast(103%);
  }
  &.no-retribuida {
      filter: invert(22%) sepia(12%) saturate(332%) hue-rotate(164deg) brightness(98%) contrast(95%);
  }
  
}

.filtros-calendario button {
  color: white;
  font-weight: 500;
  border-radius: 7px;
  border: none;
  height: 40px;

  &.boton-baja{
    background-color: var(--color-ausencia-baja);
  }
  &.boton-baja:hover, &.boton-baja-selected{
    border: 3px solid #721c24;
  }
  &.boton-vacaciones{
    background-color: var(--color-ausencia-vacaciones);
  }
  &.boton-vacaciones:hover, &.boton-vacaciones-selected{
    border: 3px solid #004702;
  }
  &.boton-teletrabajo{
    background-color: var(--color-ausencia-teletrabajo);
  }
  &.boton-teletrabajo:hover, &.boton-teletrabajo-selected{
    border: 3px solid #8c5933;
  }
  &.boton-otros{
    background-color: var(--color-ausencia-otros);
  }
  &.boton-otros:hover, &.boton-otros-selected{
    border: 3px solid #004085;
  }
  &.boton-no-retribuida{
    background-color: var(--color-ausencia-no-retribuida);
  }
  &.boton-no-retribuida:hover, &.boton-no-retribuida-selected{
    border: 3px solid #383d41;
  } 
  &.boton-asuntos-propios{
    background-color: var(--color-ausencia-asuntos-propios);
  }
  &.boton-asuntos-propios:hover, &.boton-asuntos-propios-selected {
    border: 3px solid #406f62;
  }
}


// evitamos que se quede el tooltip de las ausencias abiertos sobre la modal de lista ausencias en dispositivos movil
body.modal-open .tooltip-calendario-items {
  display: none;
}


.sidebar.expanded .tooltip {
  display: none!important;
}

.fill-available{
  width: -webkit-fill-available
}

@-moz-document url-prefix() {
  .fill-available{
      width: -moz-available
  }
}